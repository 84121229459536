export const menuLinks = [
  {
    title: "Strona główna",
    to: "/",
  },
  {
    title: "O nas",
    to: "/o-nas/",
    description: "",
  },

  // {
  //   title: 'Pracownicy',
  //   to: "/pracownicy/",
  //   description: 'Poznaj naszą wyjątkową kadrę, która tworzy serce naszego uniwersytetu.'
  // },
  {
    title: "Kursy",
    to: "/kursy/",
    description:
      "Zapraszamy do odkrycia naszej różnorodnej oferty kursów, które pomogą Ci rozwijać swoje umiejętności i wiedzę.",
  },
  {
    title: "Książki",
    to: "/ksiazki/",
    description:
      "Niezmiennie od wielu lat każdy kurs CEEA znajduje swoje podsumowanie pod postacią skryptu, który otrzymuje każdy uczestnik kursu w ramach pakietu powitalnego. Jeśli są Państwo zainteresowani nabyciem naszych książek zapraszamy do kontaktu.",
  },
  {
    title: "Kontakt",
    to: "/kontakt/",
    description:
      "Zapraszamy do kontaktu. Jesteśmy tutaj, aby odpowiedzieć na Twoje pytania i rozmawiać o tym, jak możemy Ci pomóc.",
  },
  {
    title: "FAQ",
    to: "/faq/",
    description: "Znajdź odpowiedzi na najczęściej zadawane pytania.",
  },
]

export const przydatneLinki = [
  {
    title: "Archiwum kursów",
    to: "/archiwum_kursow/",
    description:
      "Zanurz się w naszym bogatym archiwum kursów, które stanowią skarbnicę wiedzy i doświadczeń. Nasze archiwum kursów to miejsce, gdzie możesz odkryć różnorodność i głębię tematów, które oferujemy.",
  },
]

export const otherLinks = [
  {
    title: "Polityka prywatności",
    to: "/polityka-prywatnosci/",
    description:
      "Zalecamy zapoznanie się z naszą polityką prywatności, aby dowiedzieć się, jak chronimy Twoje dane i zapewniamy Ci kontrolę nad nimi.",
  },
  // {
  //   title: "Regulamin",
  //   to: "/regulamin/",
  //   description: "Dowiedz się więcej o naszym uniwersytecie.",
  // },
  {
    title: "Sitemap",
    to: "/sitemap-index.xml/",
    description: "Dowiedz się więcej o naszym uniwersytecie.",
  },
  {
    title: "Rejestracja",
    to: "/*/rejestracja/",
    description: "Dowiedz się więcej o naszym uniwersytecie.",
  },
]
