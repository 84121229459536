exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archiwum-kursow-js": () => import("./../../../src/pages/archiwum_kursow.js" /* webpackChunkName: "component---src-pages-archiwum-kursow-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-graphql-js": () => import("./../../../src/pages/graphql.js" /* webpackChunkName: "component---src-pages-graphql-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-ksiazki-js": () => import("./../../../src/pages/ksiazki.js" /* webpackChunkName: "component---src-pages-ksiazki-js" */),
  "component---src-pages-kursy-js": () => import("./../../../src/pages/kursy.js" /* webpackChunkName: "component---src-pages-kursy-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-pracownicy-js": () => import("./../../../src/pages/pracownicy.js" /* webpackChunkName: "component---src-pages-pracownicy-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-course-details-js": () => import("./../../../src/templates/course-details.js" /* webpackChunkName: "component---src-templates-course-details-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-registration-js": () => import("./../../../src/templates/registration.js" /* webpackChunkName: "component---src-templates-registration-js" */)
}

